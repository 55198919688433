import React, { FC, useState } from 'react';
import { graphql } from 'gatsby';
import { StateTypes } from 'types';
import {
  Locations,
  PageLayout,
  PageHeading,
  EnquireFormModal,
} from 'components';
import ReactMarkdown from 'react-markdown';
import { SlideUpPageContent } from 'components/animations';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const State: FC<StateTypes> = ({ data, pageContext }: any) => {
  const {
    strapiLocationStates: {
      heading,
      content,
      seo,
      Banner,
      tagline,
      description,
      image,
      name,
      title,
      EnquireButtonText,
      ContactFormModalTitle,
    },
    LocationFilter,
    locations,
    strapiGlobal: { banner },
  } = data;
  const Image =
    image && image.localFile
      ? getImage(image.localFile.childImageSharp?.gatsbyImageData)
      : null;
  const [isEnquireModalOpen, setisEnquireModalOpen] = useState(false);

  const handleEnquireModalOpen = () => {
    setisEnquireModalOpen(true);
  };

  return (
    <PageLayout
      title="Locations"
      metaTitle={seo?.metaTitle ?? ''}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      bannerTitle={Banner?.title || banner?.title}
      bannerTagline={Banner?.tagline || banner?.tagline}
      bannerImage={Banner?.image || banner?.image}
      bannerButton={Banner?.ctaButton || banner?.ctaButton}
      bannerPlaceholder={
        Banner?.postcodePlaceholder || banner?.postcodePlaceholder
      }
    >
      <div className="container">
        <PageHeading title={heading} subtitle={tagline} level={1} />
        <SlideUpPageContent>
          <div className="service-page-description">
            {description && (
              <div className="service-page-description-content">
                <ReactMarkdown className="md-html">{description}</ReactMarkdown>
              </div>
            )}
            {Image && (
              <div className="service-page-image">
                <GatsbyImage
                  image={Image}
                  alt={(image?.alternativeText && name) || ''}
                />
              </div>
            )}
          </div>
        </SlideUpPageContent>
        {EnquireButtonText && (
          <div className="service-page-cta-link-container">
            <button
              className="service-page-cta-link"
              type="button"
              onClick={handleEnquireModalOpen}
            >
              <span>{EnquireButtonText}</span>
            </button>
          </div>
        )}
        <Locations
          LocationFilter={LocationFilter}
          locations={locations}
          heading={title}
          contactUsUrl="location"
          selectedCity={pageContext.name}
          content={content}
        />
        <EnquireFormModal
          isEnquireModalOpen={isEnquireModalOpen}
          setisEnquireModalOpen={setisEnquireModalOpen}
          contactFormModalTitle={ContactFormModalTitle}
          modalText=""
          optionalMessage
          dealTitle={title}
        />
      </div>
    </PageLayout>
  );
};

export const query = graphql`
  query LocationStatesQuery($id: String) {
    strapiLocationStates(id: { eq: $id }) {
      title
      id
      name
      content
      heading
      tagline
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Banner {
        ctaButton
        title
        tagline
        postcodePlaceholder
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      ContactFormModalTitle
      EnquireButtonText
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
    }
    locations: allStrapiLocations {
      edges {
        node {
          id
          title
          slug
          address
          location_state {
            name
          }
        }
      }
    }
    LocationFilter: allStrapiLocationStates {
      edges {
        node {
          id
          title
          name
          slug
        }
      }
    }
    strapiGlobal {
      banner: defaultBanner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default State;
